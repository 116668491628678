import { useState } from "react";
import Select from "react-select";
import React from "react";
import NavBar from "../../components/navBar";
import { Report_System_URL } from "../../global";
import Loading from "../../components/loading";
import moment from "moment";
import * as XLSX from "xlsx";

function VendorPaymentsReport() {
  const [loading, setLoading] = useState(false);

  const reportType = {
    vendor_payment: 0,
    vendor_payment_orders: 1,
  };

  const dateType = [
    { label: "[Year-Week]", value: 0 },
    { label: "[Year-Month]", value: 1 },
    { label: "[2 weeks Month Year]", value: 2 },
  ];

  const [reportData, setReportData] = useState([]);

  const [selectedType, setSelectedType] = useState({
    label: "[Year-Week]",
    value: 0,
  });

  const [reportYear, setReportYear] = useState("");
  const [reportPeriod, setReportPeriod] = useState("");

  const [year, setYear] = useState(2023);

  const [weeks, setWeeks] = useState([]);

  const [selectedWeekPeriod, setSelectedWeekPeriod] = useState("");

  let weeksList = [];
  const dateFormatter = (cell) => {
    return cell != null ? moment(cell).format("MM/DD/YYYY") : "";
  };

  async function loadWeeksForGivenYear() {
    setLoading(true);

    var token = localStorage.getItem("token");

    if ((year.length === 0) | (year.length < 4) | (year.length > 4)) {
      alert("please add year like 2022 or 2023 ");
      return;
    }

    fetch(Report_System_URL + `reports/get_two_weeks_data?year=${year}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let weeks = response;

        weeks.forEach((twoWeek) => {
          weeksList.push({
            label: twoWeek,
            value: twoWeek,
          });
        });
        setWeeks(weeksList);

        setLoading(false);
      });

    setLoading(false);
  }

  async function getReport(requestedReport) {
    setLoading(true);

    var token = localStorage.getItem("token");

    let url = "";

    if (requestedReport === reportType.vendor_payment) {
      url =
        Report_System_URL +
        `reports/get_payment_vendors?date=${
          selectedType.value === 2
            ? selectedWeekPeriod.replace(/ /g, "%20")
            : reportYear.toString() + "-" + reportPeriod.toString()
        }&type=${selectedType.value}`;
    } else {
      url =
        Report_System_URL +
        `reports/get_payment_vendors_orders?date=${
          selectedType.value === 2
            ? selectedWeekPeriod.replace(/ /g, "%20")
            : reportYear.toString() + "-" + reportPeriod.toString()
        }&type=${selectedType.value}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let reportDataServer = response;

        if (reportDataServer.detail) {
          alert(reportDataServer.detail);
          setLoading(false);
          return;
        }

        JSONToExcel(reportDataServer, requestedReport);

        setLoading(false);
      });
    setLoading(false);
  }

  // convert report data to excel

  const JSONToExcel = (reportData, requestedReport) => {
    let fileName = "";
    if (requestedReport === reportType.vendor_payment) {
      fileName = `Vendor Payment    For Year ${reportYear} ${
        selectedType.value === 0
          ? "And Weak " + reportPeriod
          : "And Month " + reportPeriod
      }`;
    } else {
      fileName = `Vendors Orders Payment  For Year ${reportYear} ${
        selectedType.value === 0
          ? "And Weak " + reportPeriod
          : "And Month " + reportPeriod
      } `;
    }

    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <NavBar />
      <div className="container-fluid text-center">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-2">
            {/* Given Year */}
            <div className="container border-bottom border-light border-3   m-2 p-2">
              <input
                disabled={selectedType.value === 2 ? false : true}
                type="number"
                className="form-control"
                value={year}
                id="year"
                placeholder="2022,2023"
                name="year"
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              />
            </div>
          </div>
          {/*  */}
          <div className="col-md-1">
            <div
              className={`btn btn-primary border border-dark ${
                selectedType.value === 2 ? "" : "disabled"
              }`}
              onClick={async () => {
                await loadWeeksForGivenYear();
              }}
              d
            >
              Get Weeks
            </div>
          </div>

          {/*  */}
          <div className="col-md-3">
            <div className="container-fluid p-4 bg-light">
              <Select
                isDisabled={selectedType.value === 2 ? false : true}
                placeholder={"Two Weeks"}
                options={weeks}
                onChange={(opt) => setSelectedWeekPeriod(opt.value)}
                // isMulti
              />
            </div>
          </div>
          {/*  */}
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-6">
                <input
                  disabled={selectedType.value === 2 ? true : false}
                  type="number"
                  className="form-control"
                  value={reportYear}
                  id="reportYear"
                  placeholder="Year"
                  name="reportYear"
                  onChange={(e) => {
                    setReportYear(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6">
                <input
                  disabled={selectedType.value === 2 ? true : false}
                  type="number"
                  className="form-control"
                  id="reportPeriod"
                  value={reportPeriod}
                  placeholder={
                    selectedType.value === 0
                      ? "Weak"
                      : selectedType.value === 1
                      ? "Month"
                      : ""
                  }
                  name="reportPeriod"
                  onChange={(e) => {
                    setReportPeriod(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-md-3">
            <div className="container-fluid border rounded p-2 bg-light">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-4">Choose Type</div>
                <div className="col-md-8">
                  <Select
                    value={selectedType}
                    options={dateType}
                    onChange={(opt) => setSelectedType(opt)}
                    // isMulti
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <div
              className="container btn btn-light border border-success border-3"
              onClick={async () => {
                //give the report type by sending a string that indicates the type of the report
                await getReport(reportType.vendor_payment);
              }}
            >
              <b> Get Vendor Payment </b>
            </div>
          </div>
          <div className="col-md-2">
            <div
              className="container btn btn-light border border-danger border-3"
              onClick={async () => {
                await getReport(reportType.vendor_payment_orders);
              }}
            >
              <b> Get Vendor Payment Orders</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorPaymentsReport;
