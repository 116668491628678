import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import React, { useState } from "react";
import Loading from "../../components/loading";
import NavBar from "../../components/navBar";
import ExcelExport from "../../components/excelExport";
import { useNavigate } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import { Report_System_URL } from "../../global";
import { useEffect } from "react";
import Select from "react-select";

function CancellationReport() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [startFirstDate, setStartFirstDate] = useState(new Date());
  const [endFirstDate, setEndFirstDate] = useState(new Date());
  const [accountManagers, setAccountManagers] = useState([]);
  const [vendorsDropDownMenu, setVendorsDropDownMenu] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedAccountManager, setSelectedAccountManager] = useState();

  const [accountManagersDropDownMenu, setAccountManagersDropDownMenu] =
    useState([]);

  const [vendors, setVendors] = useState([]);

  const [data, setData] = useState([]);

  let dropVendors = [];

  let dropDownMenuAccountManagersList = [];

  function getReport() {
    setLoading(true);

    var token = localStorage.getItem("token");

    let formattedFirstDateStart = new Date(startFirstDate)
      .toISOString()
      .slice(0, 10);
    let formattedFirstDateEnd = new Date(endFirstDate)
      .toISOString()
      .slice(0, 10);

    fetch(Report_System_URL + `reports/get_cancellation_report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        start_date: formattedFirstDateStart,
        end_date: formattedFirstDateEnd,
        vendors: Object.values(selectedVendors).map(
          (vendorId, i) => vendorId.value
        ),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          navigate("/home");
        }
        setData(response);
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  }

  function loadAccountManagers() {
    setLoading(true);
    var token = localStorage.getItem("token");

    fetch(Report_System_URL + "account_manager/all_am", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          setLoading(false);
          navigate("/home");
        }

        let accountManagers = response;

        setAccountManagers(accountManagers);
        accountManagers.forEach((accountManager) => {
          dropDownMenuAccountManagersList.push({
            label: accountManager.username,
            value: accountManager.id,
          });
        });
        setAccountManagersDropDownMenu(dropDownMenuAccountManagersList);
        setLoading(false);
      });

    setLoading(false);
  }

  function loadVendors() {
    setLoading(true);
    let response = null;

    var token = localStorage.getItem("token");

    fetch(Report_System_URL + "reports/get_vendor_id", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          setLoading(false);
          navigate("/home");
        }

        let vendors = response;

        setVendors(vendors);
        vendors.forEach((vendor) => {
          dropVendors.push({
            label: vendor.vendor_title,
            value: vendor.vendor_id,
          });
        });
        setVendorsDropDownMenu(dropVendors);
        setLoading(false);
      });
  }

  async function getAllVendorsForCurrentAccountManager(accountManagerObject) {
    setLoading(true);

    setSelectedAccountManager(accountManagerObject);
    var token = localStorage.getItem("token");

    localStorage.setItem("accountManager", accountManagerObject.label);

    await fetch(
      Report_System_URL +
        `account_manager/get_vendors_of_am?am_id=${accountManagerObject.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          setLoading(false);
          navigate("/home");
        }
        let dropVendors = [];
        response.forEach((vendor) => {
          dropVendors.push({
            label: vendor.vendor_title,
            value: vendor.vendor_id,
          });
        });

        setVendorsDropDownMenu(dropVendors);
        setSelectedVendors(dropVendors);

        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    loadVendors();
    loadAccountManagers();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <NavBar />
      <div className="container p-2 mt-2   border-2 border-bottom border-primary text-dark rounded">
        <h3 className="text-center">
          <b> Cancellation Report</b>{" "}
        </h3>
      </div>

      <div className="row p-2 m-2 rounded border">
        <div className="col-md-6">
          <div className="container p-2 m-2 ">
            {" "}
            <b>Account Manager Vendors </b>
          </div>
          <div className="container border-bottom border-light border-3 ">
            <Select
              defaultValue={selectedVendors}
              options={vendorsDropDownMenu}
              onChange={(opt) => setSelectedVendors(opt)}
              isMulti
              placeholder={"vendors.."}
              isDisabled={true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="contianer m-2 p-2">Select Account Manager</div>
          <div className="container border-bottom border-light border-3 ">
            <Select
              value={selectedAccountManager}
              defaultValue={selectedAccountManager}
              options={accountManagersDropDownMenu}
              onChange={async (opt) =>
                await getAllVendorsForCurrentAccountManager(opt)
              }
              placeholder={"Select Account Manager"}
              // isDisabled={iscompareReport ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="container border border-4 border-dark  rounded p-2 mt-5 mb-2">
        <div className="row text-center bg-light ">
          <div className="col-md-3">
            <div className="container p-2">
              Start Date
              <DateTimePicker
                key={1}
                clearIcon={null}
                format={"y-MM-dd"}
                onChange={setStartFirstDate}
                value={startFirstDate}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="container p-2">
              End Date
              <DateTimePicker
                key={1}
                clearIcon={null}
                format={"y-MM-dd"}
                onChange={setEndFirstDate}
                value={endFirstDate}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div
              className="container btn btn-light border border-primary text-primary"
              onClick={getReport}
            >
              <b> Get Report </b>
            </div>
          </div>
          <div className="col-md-3">
            {ExcelExport(
              data,
              "Cancellation_Report",
              startFirstDate,
              endFirstDate
            )}
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table   table-bordered table-dark table-hover">
          <thead>
            <tr className="text-center">
              {/* view all of the selected days from the returned object by iterating throw it  */}
              {data.length === 0
                ? ""
                : Object.keys(data[0]).map((header, index) => [
                    <th
                      key={index}
                      style={{
                        minWidth: 100,
                        width: 100,
                        textAlign: "center",
                      }}
                    >
                      {header}
                    </th>,
                  ])}
            </tr>
          </thead>
          <tbody className="text-center">
            {data.length === 0 ? (
              <p className="text-dark">
                {" "}
                Please Select Start and End Date and Press Get Report 😁
              </p>
            ) : (
              Object.values(data).map((h, i) => [
                <tr key={i}>
                  {Object.values(h).map((sh, si) => (
                    <td key={si}>{sh}</td>
                  ))}
                </tr>,
              ])
            )}
          </tbody>
        </table>
      </div>

      {/* <div className="container">
        <LineChart
          width={1000}
          height={300}
          data={Object.values(data)
            .slice(1, 0)
            .map((record) => {
              return {
                name: record.hour,
                gross_orders: record.gross_orders,
                net_orders: record.net_orders,
                cancelled_orders: record.cancelled_orders,
                pv: 2400,
                amt: 2400,
              };
            })}
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
        >
          <Line type="monotone" dataKey="gross_orders" stroke="#8884d8" />
          <Line type="monotone" dataKey="net_orders" stroke="green" />
          <Line type="monotone" dataKey="cancelled_orders" stroke="red" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </div> */}
    </>
  );
}

export default CancellationReport;
