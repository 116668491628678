// for excel and pdf
import * as XLSX from "xlsx";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import React, { useEffect, useState } from "react";
import Loading from "../../components/loading";
import NavBar from "../../components/navBar";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import { Report_System_URL } from "../../global";
import { useNavigate } from "react-router-dom";

function VendorAmKPIReport() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [startFirstDate, setStartFirstDate] = useState(new Date());
  const [endFirstDate, setEndFirstDate] = useState(new Date());

  const [startSecondDate, setStartSecondDate] = useState(new Date());
  const [endSecondDate, setEndSecondDate] = useState(new Date());
  const [data, setData] = useState([]);

  const [iscompareReport, setIsCompareReport] = useState(false);

  const [selectedVendors, setSelectedVendors] = useState([]);

  const [vendorsDropDownMenu, setVendorsDropDownMenu] = useState([]);
  const [vendors, setVendors] = useState([]);

  let dropVendors = [];

  const [selectedAccountManager, setSelectedAccountManager] = useState();

  const [accountManagersDropDownMenu, setAccountManagersDropDownMenu] =
    useState([]);
  const [accountManagers, setAccountManagers] = useState([]);

  let dropDownMenuAccountManagersList = [];

  async function getReport() {
    setLoading(true);

    var token = localStorage.getItem("token");

    let vendorsId = [];

    selectedVendors.map((v) => vendorsId.push(v.value));

    console.log(Object.values(selectedVendors).map((h, i) => h.value));

    fetch(Report_System_URL + `reports/get_am_vendor_kpi_report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(
        Object.values(selectedVendors).map((h, i) => h.value)
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          setLoading(false);
          navigate("/home");
        }

        setData(response);

        console.log(response);

        setTimeout(() => {}, 1000);

        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
    setLoading(false);
  }

  async function loadVendors() {
    setLoading(true);
    let response = null;

    var token = localStorage.getItem("token");

    fetch(Report_System_URL + "reports/get_vendor_id", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          setLoading(false);
          navigate("/home");
        }

        let vendors = response;

        setVendors(vendors);
        vendors.forEach((vendor) => {
          dropVendors.push({
            label: vendor.vendor_title,
            value: vendor.vendor_id,
          });
        });
        setVendorsDropDownMenu(dropVendors);
        setLoading(false);
      });
  }

  async function loadAccountManagers() {
    setLoading(true);
    var token = localStorage.getItem("token");

    fetch(Report_System_URL + "account_manager/all_am", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          setLoading(false);
          navigate("/home");
        }

        let accountManagers = response;

        setAccountManagers(accountManagers);
        accountManagers.forEach((accountManager) => {
          dropDownMenuAccountManagersList.push({
            label: accountManager.username,
            value: accountManager.id,
          });
        });
        setAccountManagersDropDownMenu(dropDownMenuAccountManagersList);
        setLoading(false);
      });

    setLoading(false);
  }

  async function getAllVendorsForCurrentAccountManager(accountManagerObject) {
    setLoading(true);

    setSelectedAccountManager(accountManagerObject);
    var token = localStorage.getItem("token");

    localStorage.setItem("accountManager", accountManagerObject.label);

    await fetch(
      Report_System_URL +
        `account_manager/get_vendors_of_am?am_id=${accountManagerObject.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          setLoading(false);
          navigate("/home");
        }
        let dropVendors = [];
        response.forEach((vendor) => {
          dropVendors.push({
            label: vendor.vendor_title,
            value: vendor.vendor_id,
          });
        });

        setVendorsDropDownMenu(dropVendors);
        setSelectedVendors(dropVendors);

        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    loadVendors();
    loadAccountManagers();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <NavBar />
      <div className="container p-2 mt-2  mb-2   border-2 border-bottom border-primary text-dark rounded ">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-12">
            <h3 className="text-center">
              <b>AM KPIs & Units Economics Report</b>
            </h3>
          </div>
        </div>
      </div>
      <div className="container-fluid p-2 m-2">
        <div className="row">
          <div className="col-md-6">
            <div className="container border-bottom border-light border-3 ">
              <Select
                defaultValue={selectedVendors}
                options={vendorsDropDownMenu}
                onChange={(opt) => setSelectedVendors(opt)}
                isMulti
                placeholder={"vendors.."}
                isDisabled={true}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="container border-bottom border-light border-3 ">
              <Select
                value={selectedAccountManager}
                defaultValue={selectedAccountManager}
                options={accountManagersDropDownMenu}
                onChange={async (opt) =>
                  await getAllVendorsForCurrentAccountManager(opt)
                }
                placeholder={"Select Account Manager"}
                // isDisabled={iscompareReport ? true : false}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div
              className="container btn btn-light text-primary border border-2 border-secondary"
              onClick={getReport}
            >
              <b> Get Report ✅</b>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-2 p-2">
        <div className="col-md-3 p-0 ">
          <table className="table table-md  table-bordered table-hover">
            <tbody className="text-center">
              {data.length === 0
                ? ""
                : Object.keys(data[0]).map((value, index) => (
                    <tr
                      key={index}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <td> {value === null ? "" : value} </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        <div className="col-md-9 p-0">
          <div className="table-responsive">
            <table className="table table-md  table-bordered table-hover">
              <tbody className="text-center">
                {data.length === 0
                  ? ""
                  : Object.values(data[0]).map((value, index) => (
                      <tr
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <td>
                          {" "}
                          {value === null ? "." : value} {""}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorAmKPIReport;
