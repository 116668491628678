import jsPDF from "jspdf";
import NavBar from "../../components/navBar";
import DateTimePicker from "react-datetime-picker";
import { useState, useRef } from "react";
import Select from "react-select";
import { Report_System_URL } from "../../global";
import { useEffect } from "react";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import font from "./Amiri-Regular-normal";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import balyEnglishLogo from "./baly_logo_eng.jpg";
import balyArabicLogo from "./baly_logo_arbic.jpg";

import html2canvas from "html2canvas";
function VendorReceiptReport() {
  const navigate = useNavigate();

  const [startFirstDate, setStartFirstDate] = useState(new Date());
  const [endFirstDate, setEndFirstDate] = useState(new Date());
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);

  async function exportToPDF() {
    const pdf = new jsPDF("landscape");

    let formattedFirstDateStart = new Date(startFirstDate)
      .toISOString()
      .slice(0, 10);
    let formattedFirstDateEnd = new Date(endFirstDate)
      .toISOString()
      .slice(0, 10);

    // pdf.addFileToVFS("Amiri-Regular-normal.ttf", font);
    // pdf.addFont("Amiri-Regular-normal.ttf", "Amiri-Regular", "normal");

    // pdf.setFont("Amiri-Regular", "normal");

    const input = tableRef.current;
    html2canvas(input, { scale: 1.0 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        // orientation: "landscape", // Set orientation to landscape
        unit: "mm", // Use millimeters as the unit for dimensions
        format: "a4", // Use A4 size paper
      });

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Define margin values
      const marginLeft = 10; // Left margin in mm
      const marginRight = 10; // Right margin in mm
      const marginTop = 10; // Top margin in mm
      const marginBottom = 10; // Bottom margin in mm

      // Calculate the adjusted width and height with margins
      const adjustedWidth = pdfWidth - marginLeft - marginRight;
      const adjustedHeight = pdfHeight - marginTop - marginBottom;

      // Calculate the x and y positions to center the adjusted table
      const xPosition =
        marginLeft + (pdf.internal.pageSize.getWidth() - pdfWidth) / 2;
      const yPosition =
        marginTop + (pdf.internal.pageSize.getHeight() - pdfHeight) / 8;

      pdf.addImage(
        imgData,
        "PNG",
        xPosition,
        yPosition,
        adjustedWidth,
        adjustedHeight
      );
      pdf.save(
        `Vendor Receipt - ${selectedVendor.label} - from ${formattedFirstDateStart}- to ${formattedFirstDateEnd}.pdf`
      );
    });
  }

  async function getReport() {
    setLoading(true);

    if (selectedVendor.length === 0) {
      alert("Please Select Vendor !");
      setLoading(false);

      return;
    }
    var token = localStorage.getItem("token");

    let formattedFirstDateStart = new Date(startFirstDate)
      .toISOString()
      .slice(0, 10);
    let formattedFirstDateEnd = new Date(endFirstDate)
      .toISOString()
      .slice(0, 10);

    if (selectedVendor === null || selectedVendor === undefined) {
      alert("please select a vendor ");
      return;
    }

    fetch(
      Report_System_URL +
        `reports/get_vendor_billing?start_date=${formattedFirstDateStart}&end_date=${formattedFirstDateEnd}&vendor_id=${selectedVendor.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          alert("choose small period");
          setData([]);

          navigate("/home");
          setLoading(false);

          return response.status;
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        alert(error + "   😕");
        setLoading(false);
      });
  }

  const [selectedVendor, setSelectedVendor] = useState("");

  const [vendorsDropDownMenu, setVendorsDropDownMenu] = useState([]);
  const [vendors, setVendors] = useState([]);
  let dropVendors = [];

  async function loadVendors() {
    setLoading(true);
    let response = null;

    var token = localStorage.getItem("token");

    fetch(Report_System_URL + "reports/get_vendor_id", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.detail) {
          alert(response.detail);
          setLoading(false);
          navigate("/home");
        }

        let vendors = response;

        setVendors(vendors);
        vendors.forEach((vendor) => {
          dropVendors.push({
            label: vendor.vendor_title,
            value: vendor.vendor_id,
          });
        });
        setVendorsDropDownMenu(dropVendors);
      });
    setLoading(false);
  }

  useEffect(() => {
    loadVendors();
  }, []);

  const fields = [
    {
      dataField: "skip_pay",
      text: "skip_pay",
      showTitle: false,
    },
    {
      dataField: "final_pay",
      text: "final_pay",
    },
    {
      dataField: "bill_day",
      text: "bill_day",
    },
    {
      dataField: "description",
      text: "description",
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <NavBar />

      <div className="container-fluid p-2 mt-2   border-2 border-bottom border-primary text-dark rounded ">
        <h3 className="text-center" id="test">
          <b> Vendor Receipt </b>
        </h3>
        <div className="container border  rounded p-4 mt-2 mb-2 ">
          <div className="row">
            <div className="col-md-2">
              <div className="container   ">
                Start
                <DateTimePicker
                  key={1}
                  clearIcon={null}
                  format={"y-MM-dd"}
                  onChange={setStartFirstDate}
                  value={startFirstDate}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="container   ">
                End
                <DateTimePicker
                  key={2}
                  clearIcon={null}
                  format={"y-MM-dd"}
                  onChange={setEndFirstDate}
                  value={endFirstDate}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="container border-bottom border-light border-3  ">
                <Select
                  defaultValue={selectedVendor}
                  options={vendorsDropDownMenu}
                  onChange={(opt) => setSelectedVendor(opt)}
                  placeholder={"vendors.."}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="container btn btn-light border border-2 border-primary text-primary"
                onClick={getReport}
              >
                <b> Get Report</b>
              </div>
            </div>
            <div className="col-md-2">
              <div className="container text-center">
                <div
                  className="container btn border border-2  border-danger text-danger  text-center"
                  onClick={exportToPDF}
                >
                  <b> Export Receipt 📁 </b>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <div
            className="container-fluid"
            style={{ height: 800, overflow: "auto" }}
          >
            <div className="container-fluid">
              <div
                className="table-responsive text-center"
                id="mytable"
                ref={tableRef}
              >
                {!data || loading ? (
                  <div className="container bg-success p-4 m-4 rounded text-light">
                    <b>{loading ? "LOADING..." : "No DATA Get Report First"}</b>
                  </div>
                ) : (
                  <table className="table p-2 text-center">
                    <thead>
                      <tr>
                        <td className="text-start p-2">
                          <img
                            src={balyEnglishLogo}
                            height={75}
                            alt=""
                            srcset=""
                          />
                        </td>
                        <td colSpan={2} className="text-center text-primary">
                          <h4>
                            <b>
                              شركة بريق النهرين لخدمات التوصيل ونقل المسافرين
                              محدودة المسؤولية
                            </b>
                          </h4>
                        </td>

                        <td className="text-end p-2">
                          <img
                            src={balyArabicLogo}
                            height={75}
                            alt=""
                            srcset=""
                          />
                        </td>
                      </tr>

                      <tr className="border boder-dark border-4 m-4 rounded">
                        <td colSpan={4} className="text-center">
                          <h2>
                            <b> وصل قبض</b>
                          </h2>
                        </td>
                      </tr>

                      <tr>
                        <td></td> <td></td> <td></td>{" "}
                        <td className="text-end">
                          {" "}
                          <h4> التاريخ : {data.bill_day}</h4>
                        </td>
                      </tr>
                      <tr>
                        <td></td> <td></td> <td></td>{" "}
                        <td className="text-end">
                          {" "}
                          <h3> : مدفوعة لـ </h3>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="p-2"></th>
                        <th className="p-2"></th>
                        <th className="p-2 text-end">
                          <h3>
                            {" "}
                            {"IQD " + data.final_pay.toLocaleString() ?? 0}
                          </h3>
                        </th>
                        <th className="p-2">
                          <h3> المبلغ رقما </h3>
                        </th>
                      </tr>
                      <tr>
                        <th className="p-2"></th>
                        <th className="p-2"></th>
                        <th className="p-2 text-end">
                          {" "}
                          <h3> {data.skip_pay}</h3>
                        </th>
                        <th className="p-2">
                          <h3> المبلغ كتابة </h3>
                        </th>
                      </tr>
                      <tr>
                        <th className="p-2"></th>
                        <th className="p-2"></th>
                        <th className="p-2"></th>

                        <th className="p-2  text-end">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-6"></div>
                            <div className="col-md-4">
                              <h3> نقدا </h3>
                            </div>
                            <div className="col-md-2">
                              <div
                                className="container border border-rounded border-2 border-dark pt-2 pb-2 pr-1 pl-1"
                                style={{ width: "20px" }}
                              ></div>
                            </div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th className="p-2"></th>
                        <th className="p-2"></th>
                        <th className="p-2"></th>

                        <th className="p-2 text-end">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-6">
                              <h3> : رقم</h3>
                            </div>
                            <div className="col-md-4">
                              <h3> صك </h3>
                            </div>
                            <div className="col-md-2">
                              <div
                                className="container border border-rounded border-2 border-dark pt-2 pb-2 pr-1 pl-1"
                                style={{ width: "20px" }}
                              ></div>
                            </div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th className="p-2"></th>
                        <th className="p-2"></th>
                        <th className="text-end">
                          <h3>
                            <span dir="ltr">
                              <b>
                                {selectedVendor.label} {"  "}
                              </b>
                            </span>
                            و ذلك عن استلام مستحقات مطعم
                          </h3>
                        </th>
                        <th className="p-2">
                          <h3> الوصف </h3>
                        </th>
                      </tr>
                      <tr>
                        <th className="p-2"></th>
                        <th className="p-2"></th>
                        <th className="text-end">
                          <h4>
                            من{" "}
                            {new Date(startFirstDate)
                              .toISOString()
                              .slice(0, 10)}{" "}
                            الى{" "}
                            {new Date(endFirstDate).toISOString().slice(0, 10)}
                          </h4>
                        </th>
                        <th className="p-2">
                          <h3> الفترة </h3>
                        </th>
                      </tr>
                      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
                      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
                      <br />
                      <tr>
                        <td></td> <td></td> <td></td>{" "}
                        <td className="text-end">
                          {" "}
                          <h4> : اسم وتوقيع المستلم </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorReceiptReport;
