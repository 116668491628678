import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Loading from "../../../components/loading";
import NavBar from "../../../components/navBar";
import { Report_System_URL } from "../../../global";

function VendorDetails({ id }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [ownerName, setOwnerName] = useState("");
  const [OwnerPhoneNumber, setPhoneNumber] = useState("");

  const [selectedAccountManager, setSelectedAccountManager] = useState("");

  const [accountManagerDropDown, setAccountManagerDropDown] = useState([]);

  //pay types

  const [payType, setPayType] = useState("Cash");
  const payTypesOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Qi", value: "Qi" },
    { label: "Zain Cash", value: "Zain Cash" },
  ];

  //pay period
  const [payPeriod, setPayPeriod] = useState("Weekly");
  const payPeriodOptions = [
    { label: "Weekly", value: "Weekly" },
    { label: "2 Weeks", value: "2 Week" },
    { label: "Monthly", value: "Monthly" },
  ];

  // if zain cash or Qi is selected then the number of the account must be presented
  const [bankAccountNumber, setBankAccountNumber] = useState(0);

  let accountManagersList = [];

  const location = useLocation();

  const fields = [
    {
      dataField: "account_manager_username",
      text: "Account Manager Username",
    },

    {
      dataField: "vendor_title",
      text: "Vendor Title",
    },
  ];

  var data = [
    {
      id: location.state.id,
      vendor_id: location.state.vendor_id,
      account_manager_id: location.state.account_manager_id,
      account_manager_username: location.state.account_manager_username,
      vendor_title: location.state.vendor_title,
      number: location.state.number,
      pay_period: location.state.pay_period,
      pay_type: location.state.pay_type,
      owner_name: location.state.owner_name,
      owner_phone: location.state.owner_phone,
    },
  ];

  async function loadAllAccountManagers() {
    setLoading(true);

    var token = localStorage.getItem("token");

    let res = await fetch(Report_System_URL + "account_manager/all_am", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let accountManagers = await res.json();

    accountManagers.forEach((accountManager) => {
      accountManagersList.push({
        label: accountManager.username,
        value: accountManager.id,
      });
    });

    setAccountManagerDropDown(accountManagersList);
    setLoading(false);
  }

  async function updateVendorAccountManager() {
    setLoading(true);

    var token = localStorage.getItem("token");

    let res = null;
    fetch(Report_System_URL + `account_manager/assign_vendor_am`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        account_manager_id: selectedAccountManager,
        vendor: data[0].vendor_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        res = data;
        navigate("/vendors");
      })
      .catch((error) => {
        console.log(error);
        alert("Error In Updating Vendor 😕");
      });

    if (res !== null) {
      alert(
        "account manager for vendor " +
          location.state.vendor_title +
          " updated "
      );
    }
    setLoading(false);
  }

  async function updatePayTypesAndPeriod() {
    setLoading(true);

    var token = localStorage.getItem("token");

    let vendroPaysDetailsList = [];

    vendroPaysDetailsList.push({
      vendor_id: data[0].vendor_id,
      pay_type: payType,
      number: bankAccountNumber,
      pay_period: payPeriod,
      owner_name: ownerName,
      owner_phone: OwnerPhoneNumber,
    });

    let res = null;
    fetch(Report_System_URL + `account_manager/set_data_vendors`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(vendroPaysDetailsList),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        res = data;
        navigate("/vendors");
      })
      .catch((error) => {
        console.log(error);
        alert("Error In Updating Vendor 😕");
      });

    if (res !== null) {
      alert(
        "account manager for vendor " +
          location.state.vendor_title +
          " updated "
      );
    }
    setLoading(false);
  }

  useEffect(() => {
    loadAllAccountManagers();
    setOwnerName(data[0].owner_name);
    setPhoneNumber(data[0].setPhoneNumber);
    setBankAccountNumber(data[0].number);
    setPayPeriod(data[0].pay_period);
    setPayType(data[0].pay_type);
  }, []);

  function titleWithTextField(title, value) {
    return (
      <div className="row  text-dark border rounded border-2 border-dark m-2">
        <div className="col-md-6">
          <h5>{title}</h5>
        </div>
        <div className="col-md-6">
          <h5>
            <b>{value}</b>{" "}
          </h5>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <NavBar />

      {/* div for changing the current vendor pay types and pay period */}
      <div className="container text-center">
        <h1>{data[0].vendor_title}</h1>
      </div>
      <div className="container mt-2 p-2 border rounded">
        <table className="table">
          <tbody>
            <tr>
              <td>Current Assigned to</td>
              <td>
                <b> {location.state.account_manager_username} </b>
              </td>
            </tr>
            <tr>
              <td>Assign To</td>
              <td>
                <Select
                  options={accountManagerDropDown}
                  onChange={(opt) => {
                    setSelectedAccountManager(opt.value);
                  }}
                  // isMulti
                />
              </td>
              <td>
                <div
                  className="btn btn-success m-2"
                  onClick={async () => {
                    await updateVendorAccountManager();
                  }}
                >
                  Update Vendor
                </div>
              </td>
            </tr>
            <tr>
              <td>Pay Type</td>
              <td>
                <b> {data[0].pay_type}</b>
              </td>
            </tr>
            <tr>
              <td>Pay Period</td>
              <td>
                <b> {data[0].pay_period}</b>
              </td>
            </tr>
            <tr>
              <td>Pay Number</td>
              <td>
                <b> {data[0].number}</b>
              </td>
            </tr>
            <tr>
              <td>Owner Name</td>
              <td>
                <b> {data[0].owner_name}</b>
              </td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>
                <b> {data[0].owner_phone}</b>
              </td>
            </tr>

            <tr>
              <br />
              <div className="container text-danger">
                <h2>Vendor Payments Parameters </h2>
              </div>
            </tr>
            <tr>
              <td> Pay Type</td>
              <td>
                <b>
                  <Select
                    options={payTypesOptions}
                    defaultValue={data[0].pay_type}
                    onChange={(opt) => {
                      setPayType(opt.value);
                    }}
                  />
                </b>
              </td>
            </tr>
            <tr>
              <td> Pay Period</td>
              <td>
                <b>
                  <Select
                    defaultValue={data[0].pay_period}
                    options={payPeriodOptions}
                    onChange={(opt) => {
                      setPayPeriod(opt.value);
                    }}
                  />
                </b>
              </td>
            </tr>

            <tr
              style={{
                display:
                  (payType === "Qi") | (payType === "Zain Cash")
                    ? "table-cell"
                    : "none",
              }}
            >
              <td>Number</td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  defaultValue={data[0].number}
                  required=""
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                />
              </td>
            </tr>

            <tr>
              <td>Owner Name</td>
              <td>
                {" "}
                <input
                  className="form-control"
                  id="ownerNameTextField"
                  name="ownerNameTextField"
                  required=""
                  onChange={(e) => setOwnerName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Owner Phone</td>
              <td>
                {" "}
                <input
                  type="number"
                  className="form-control"
                  id="ownerPhoneNumberTextField"
                  name="ownerPhoneNumberTextField"
                  required=""
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>

        {/* Bank Account Number if zain cash or Qi is selected */}

        <div
          className="btn btn-success m-2"
          onClick={async () => {
            await updatePayTypesAndPeriod();
          }}
        >
          Update Vendor Payments Parameters
        </div>
      </div>
    </>
  );
}

export default VendorDetails;
