function Footer() {
  return (
    <>
      {/* Footer */}
      <footer className="rounded" style={{ marginTop: "500px" }}>
        {/* Grid container */}
        {/* Copyright */}
        <div className="text-center p-3">
          {" "}
          <b>© 2023 Copyright: By Baly BI Team </b>
        </div>
        {/* Copyright */}
      </footer>
    </>
  );
}

export default Footer;
