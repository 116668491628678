import { useState } from "react";
import NavBar from "../../../components/navBar";

function TemplateTestPage() {
  const [elements, setElements] = useState([]);
  const [textInputTitle, setTextInputTitle] = useState("");
  const [textInputStates, setTextInputStates] = useState([]);

  const addElement = () => {
    const newElement = (
      <div className="container" key={Math.random()}>
        <div className="container border-bottom border-light border-3 m-2 p-2">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-4 text-center">
              <b> {textInputTitle}</b>
            </div>

            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                id={textInputTitle}
                placeholder="..."
                name={textInputTitle}
                required=""
                value={textInputTitle}
                onChange={(e) => setTextInputStates([...e.target.value])}
              />
            </div>
          </div>
        </div>
      </div>
    );
    setElements([...elements, newElement]);
  };
  return (
    <>
      <NavBar />

      <div className="container text-center border rounded p-2 mt-1 mb-1">
        <h2> Template Sample </h2>
      </div>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-4 bg-success">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-8">
                <div className="container border-bottom border-light border-3   m-2 p-2">
                  <input
                    type="text"
                    className="form-control"
                    id={textInputTitle}
                    placeholder="..."
                    name={textInputTitle}
                    required=""
                    value={textInputTitle}
                    onChange={(e) => setTextInputTitle(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <button
                  className="btn btn-light border rounded m-1"
                  onClick={() => {
                    if (textInputTitle.length === 0) {
                      alert("error enter title for text input");
                      return;
                    }
                    addElement(textInputTitle);
                  }}
                >
                  Add Text Input
                </button>
              </div>
            </div>
          </div>
          {InsertedTemplateElements()}
        </div>
      </div>
      <div></div>
    </>
  );

  function InsertedTemplateElements() {
    return (
      <div className="col-md-8 bg-warning rounded">
        {elements.map((element, index) => (
          <div key={index}>{element}</div>
        ))}
      </div>
    );
  }
}

export default TemplateTestPage;
