import { useEffect } from "react";
import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import Loading from "./loading";
import { useNavigate } from "react-router-dom";
import { Report_System_URL } from "../global";

// db password Qymbg5QhNbAzRn!

function NavBar() {
  const navigate = useNavigate();

  let navLinkClassName = "nav-link text-dark rounded ";

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  async function handleLogout() {
    setLoading(true);
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("username");

    setLoading(false);
    // console.log(showNavBar);
    navigate("/login", { replace: true });
  }

  //get saved token and send it to backend to check its permissions
  async function checkUserPermissions() {
    setLoading(true);
    var token = localStorage.getItem("token");

    await fetch(Report_System_URL + "auth", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
      });
    setLoading(false);
  }

  useEffect(() => {
    checkUserPermissions();
  }, []);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-dark rounded p-2">
        <div className="container-fluid d-flex justify-content-between">
          {/* Start of the main navbar content */}
          <div>
            <div className="collapse navbar-collapse" id="navbarNav">
              <a className="navbar-brand text-primary border border-3 border-dark rounded p-2">
                <Link className="nav-link text-primary" to="/home">
                  <h3>
                    <b>BALY</b>
                  </h3>
                </Link>
              </a>
              <button
                className="navbar-toggler bg-dark"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item rounded m-1">
                    <Link className={navLinkClassName} to="/home">
                      <h5>Home</h5>
                    </Link>
                  </li>
                  <li className="nav-item rounded border-4 m-1">
                    <Link className={navLinkClassName} to="/users">
                      <h5>Users</h5>
                    </Link>
                  </li>
                  <li className="nav-item rounded border-4 m-1">
                    <Link className={navLinkClassName} to="/vendors">
                      <h5>Vendors</h5>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* End of the main navbar content */}

          {/* Start of user/logout buttons */}
          <div>
            <ul className="navbar-nav">
              <li
                className="nav-item btn m-1 p-2 border border-1 rounded"
                onClick={() => {
                  navigate("/user_details", {
                    state: {
                      id: localStorage.getItem("id"),
                      email: localStorage.getItem("email"),
                      username: localStorage.getItem("username"),
                      firstName: localStorage.getItem("firstName"),
                      lastName: localStorage.getItem("lastName"),
                      phoneNumber: localStorage.getItem("phoneNumber"),
                      role: localStorage.getItem("role"),
                    },
                  });
                }}
              >
                👤<b> {localStorage.getItem("username")}</b>
              </li>
              <li className="nav-item rounded m-1">
                <Link
                  className="nav-link text-light bg-danger rounded p-2 border border-3 border-danger"
                  to="/login"
                  onClick={handleLogout}
                >
                  <b>Logout</b>
                </Link>
              </li>
            </ul>
          </div>
          {/* End of user/logout buttons */}
        </div>
      </nav>

      <Outlet />
    </>
  );
}

export default NavBar;
