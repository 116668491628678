import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import Loading from "../../../components/loading";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/navBar";
import { Report_System_URL } from "../../../global";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";

function TicketsV2() {
  const navigate = useNavigate();

  const [ticketsV2, setTicketsV2] = useState([]);
  const [fields, setFields] = useState([]);

  const [loading, setLoading] = useState("false");

  const [ticketTitle, setTicketTitle] = useState("");

  const [description, setDescription] = useState("");

  let departmentList = [];

  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [departmentsList, setDepartmentList] = useState([]);

  const dateFormatter = (cell) => {
    return cell != null ? moment(cell).format("MM/DD/YYYY") : "";
  };

  const [data, setData] = useState();

  //get saved token and send it to backend to check its permissions
  async function checkUserPermissions() {
    setLoading(true);
    var token = localStorage.getItem("token");

    await fetch(Report_System_URL + "auth", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
      });
    setLoading(false);
  }

  async function GetAllTicketsV2() {
    setLoading(true);
    var token = localStorage.getItem("token");
    let res;

    await checkUserPermissions();

    console.log(data?.role);
    if (
      data?.role === "manager" ||
      data?.role === "am_lead" ||
      data?.role === "superuser"
    ) {
      res = await fetch(Report_System_URL + "ticket_system_v2/get_all_ticket", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      res = await fetch(
        Report_System_URL + "ticket_system_v2/get_all_your_department_ticket",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    let ticketJsonData = await res.json();

    let cols = Object.keys(ticketJsonData[0])
      .map((i, d) => {
        if (Array.isArray(ticketJsonData[0][i])) {
          return null; // exclude array values
        }

        //remove id column
        if (i === "id") {
          return null;
        }

        return {
          dataField: i,
          text: i,
          sort: true,
          filter: textFilter(),
          showTitle: false,
          fixed: true,
        };
      })
      .filter((col) => col !== null);

    setFields(cols);

    setTicketsV2(ticketJsonData);
    console.log(ticketJsonData);
    setLoading(false);
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      navigate("/ticket_v2_details", {
        state: {
          id: row.id,
          created: row.created,
          created_by: row.created_by,
          ticket_type: row.ticket_type,
          title: row.title,
          department: row.department,
          description: row.description,
          assign_to: row.assign_to,
          priority: row.priority,
          status: row.status,
          resolve_at: row.resolve_at,
          resolve_by: row.resolve_by,
          comment_ticketv2: row.comment_ticketv2,
          taken_by: row.taken_by,
        },
      });
    },
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: false,
  });

  //get all departments
  async function GetAllDepartment() {
    setLoading(true);

    var token = localStorage.getItem("token");

    let res = await fetch(
      Report_System_URL + "ticket_system_v2/all_department",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let departments = await res.json();

    departments.forEach((department) => {
      departmentList.push({
        label: department.name,
        value: department.id,
      });
    });

    setDepartmentList(departmentList);

    setLoading(false);
  }

  const [selectedTicketTypeId, setSelectedTicketTypeId] = useState("");
  const [ticketsTypes, setTicketsType] = useState([]);

  let ticketTypeList = [];

  //get all issue
  async function GetAllTicketType() {
    setLoading(true);

    var token = localStorage.getItem("token");

    let res = await fetch(
      Report_System_URL + "ticket_system_v2/all_ticket_types",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let ticketsTypes = await res.json();

    ticketsTypes.forEach((ticketType) => {
      ticketTypeList.push({
        label: ticketType.title,
        value: ticketType.id,
      });
    });

    setTicketsType(ticketTypeList);

    setLoading(false);
  }

  //priority
  const [priority, setPriority] = useState("");
  const priorityDropDown = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
    { label: "Urgent", value: "Urgent" },
  ];

  useEffect(() => {
    GetAllTicketType();
    GetAllDepartment();
    GetAllTicketsV2();
  }, []);

  async function addNewTicket() {
    var token = localStorage.getItem("token");

    if (
      (selectedDepartmentId === null) |
      (selectedDepartmentId === undefined) |
      (selectedDepartmentId === "")
    ) {
      alert("Please Select Department");
      return;
    }
    if (
      (selectedTicketTypeId === null) |
      (selectedTicketTypeId === undefined) |
      (selectedTicketTypeId === "")
    ) {
      alert("Please ticket Type");
      return;
    }

    if (ticketTitle === 0) {
      alert("Ticket Title ??!!");
      return;
    }
    if ((priority === null) | (priority === undefined) | (priority === "")) {
      alert("Please Select Priority");
      return;
    }

    fetch(Report_System_URL + "ticket_system_v2/create_ticket", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify({
        title: ticketTitle,
        description: description,
        department_id: selectedDepartmentId,
        priority: priority,
        ticket_type_id: selectedTicketTypeId,
        comment: "",
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        console.log(data);
        alert("new ticket added 😁");
        await GetAllTicketsV2();
      })
      .catch((error) => {
        console.log(error);
        alert("Error In Adding new ticket 😕");
      });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <NavBar />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9">
            <div className="container-fluid border m-1 p-1">
              <h3 className="text-center border rounded p-3">
                <b> General Tickets</b>
              </h3>
              <div className="table-responsive text-center">
                {fields.length === 0 && ticketsV2.length === 0 ? (
                  "loading"
                ) : (
                  <BootstrapTable
                    bordered={true}
                    hover={true}
                    keyField="id"
                    columns={fields}
                    data={ticketsV2}
                    pagination={pagination}
                    filter={filterFactory()}
                    responsive={true}
                    rowEvents={rowEvents}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="container-fluid p-2 mt-2   border-2 border-bottom border-primary text-dark rounded">
              <h3 className="text-center">
                <b> New General Ticket</b>
              </h3>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="container-fluid text-center text-dark mt-2 p-3 border border-4 rounded bg-light">
                {/* Title */}
                <div className="container text-center  border-bottom border-light border-3   m-2 p-2">
                  <b className="text-dark">Title</b>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Title..."
                    name="title"
                    required=""
                    onChange={(e) => setTicketTitle(e.target.value)}
                  />
                </div>

                {/* Description */}
                <div className="container  text-center  border-bottom border-light border-3   m-2 p-2">
                  <div className="form-group">
                    <label htmlFor="description" className="text-dark">
                      <b> Description </b>
                    </label>
                    <textarea
                      className="form-control"
                      rows={5}
                      id="description"
                      placeholder="Description ..."
                      defaultValue={""}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>

                {/* Department  */}
                <div className="container border-bottom border-light border-3   m-1 p-2">
                  <b className="text-dark">Department</b>
                  <Select
                    options={departmentsList}
                    onChange={(opt) => setSelectedDepartmentId(opt.value)}
                    // isMulti
                  />
                </div>

                {/* Ticket Type */}
                <div className="container  text-center  border-bottom border-light border-3  p-2">
                  <b className="text-dark">Ticket Type</b>
                  <Select
                    options={ticketsTypes}
                    onChange={(opt) => setSelectedTicketTypeId(opt.value)}
                  />
                </div>

                {/* Priority */}
                <div className="container border-bottom border-light border-3   m-1 p-2">
                  <b className="text-dark">Priority</b>
                  <Select
                    options={priorityDropDown}
                    onChange={(opt) => setPriority(opt.value)}
                    // isMulti
                  />
                </div>

                {/* Submit Ticket Button */}
                <div className="container mt-2 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={addNewTicket}
                  >
                    Submit Ticket
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TicketsV2;
